import React, { Fragment, useState } from "react"
import { Listbox, Transition } from '@headlessui/react'
import {
    XIcon,
    AtSymbolIcon,
    UserAddIcon,
    UserIcon,
    CheckIcon,
    SelectorIcon,
    ExclamationCircleIcon
} from '@heroicons/react/solid'
import {navigate} from "gatsby";
import {useMutation} from "@apollo/client";
import {
    CREATE_MEMBER_CLICKANDMEET_MUTATION,
    UPDATE_TENANT_CLICKANDMEET_MUTATION
} from "../../graphql/clickAndMeetGraphQL";
import {useTranslation} from "react-i18next";
import logo from "../../images/logo.png";
import dancingVideo from "../../videos/dancing.mp4";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Members(prop) {

    const { t } = useTranslation()

    const MAX_NUMBER_MEMBERS = 5
    const ERROR_DISPLAY_TIME = 5000 // 5 seconds

    const [members, setMembers] = useState([])
    const [selected, setSelected] = useState(prop.location.state != undefined ? prop.location.state.tenant.Module[0].Services[0] : [])
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")

    const [nameValidation, setNameValidation] = useState(false)
    const [emailValidation, setEmailValidation] = useState(false)
    const [validationMessage, setValidationMessage] = useState("")

    const [
        createMember,
        {loading: mutationLoadingCreateMember, error: mutationErrorCreateMember},
    ] = useMutation(CREATE_MEMBER_CLICKANDMEET_MUTATION)

    const [
        updateTenant,
        {loading: mutationLoadingUpdateTenant, error: mutationErrorUpdateTenant},
    ] = useMutation(UPDATE_TENANT_CLICKANDMEET_MUTATION)

    const services = prop.location.state != undefined ? prop.location.state.tenant.Module[0].Services : undefined

    const addMember = () => {
        if (members.length < MAX_NUMBER_MEMBERS) {

            if (name === "" || email === "") {
                if (name === "") {
                    setNameValidation(true)
                    setValidationMessage(t('meet.setup.member.add.input.field.validation'))
                }

                if (email === "") {
                    setEmailValidation(true)
                    setValidationMessage(t('meet.setup.member.add.input.field.validation'))
                }

                setTimeout(function(){
                    setNameValidation(false)
                    setEmailValidation(false)
                    setValidationMessage("")
                }.bind(this),ERROR_DISPLAY_TIME);

            } else {
                if (!members.some( member => member.email === email)) {
                    setMembers([...members, {name: name, service: selected, email: email}])
                } else {
                    setValidationMessage(t('meet.setup.member.add.input.email.validation'))
                    setTimeout(function(){
                        setEmailValidation(false)
                        setValidationMessage("")
                    }.bind(this),ERROR_DISPLAY_TIME);
                }
            }

        } else {
            setValidationMessage(t('meet.setup.validation.max.members', {max_number_members: MAX_NUMBER_MEMBERS}))
            setTimeout(function(){
                setValidationMessage("")
            }.bind(this),ERROR_DISPLAY_TIME);
        }

    }

    const handleMembers = async () => {
        if (members.length > 0) {

            try {
                let memberIds = []
                // eslint-disable-next-line no-undef
                await Promise.all(members.map(member => {
                    return createMember({
                        variables: {
                            name: member.name,
                            email: member.email,
                            services: [member.service.id],
                            availabilities: [],
                            notAvailable: []
                        },
                    }).then((res) => {
                        console.log("Create Member: ", res)
                        memberIds.push(res.data.createMember.member.id)
                    })
                }));

                updateTenant({
                    variables: {
                        tenantId: prop.location.state.tenant.id,
                        title: prop.location.state.tenant.Module[0].Title,
                        description: prop.location.state.tenant.Module[0].Description,
                        firstSlot: prop.location.state.tenant.Module[0].FirstSlot,
                        lastSlot: prop.location.state.tenant.Module[0].LastSlot,
                        slots: prop.location.state.tenant.Module[0].Slots,
                        interval: prop.location.state.tenant.Module[0].Intervall,
                        workdays: prop.location.state.tenant.Module[0].Workdays,
                        services: services.map(a => a.id),
                        members: memberIds,
                    },
                }).then((res) => {
                    navigate("/meet/widget", {
                        state: { tenant: res.data.updateTenant.tenant },
                    })
                })
            } catch (e) {
                console.log(e)
            }

        } else {
            setValidationMessage(t('meet.setup.validation.add.members'))
            setTimeout(function(){
                setValidationMessage("")
            }.bind(this),ERROR_DISPLAY_TIME);
        }
    }

    const removeMember = (email) => {
        setMembers(members.filter(item => item.email !== email));
    }

    if (mutationLoadingCreateMember) {
        console.log(mutationLoadingCreateMember)
    }

    if (mutationLoadingUpdateTenant) {
        console.log(mutationLoadingUpdateTenant)
    }

    if (mutationErrorCreateMember != undefined) {
        console.log(mutationErrorCreateMember)
    }

    if (mutationErrorUpdateTenant != undefined) {
        console.log(mutationErrorUpdateTenant)
    }

    return(
        <div className="min-h-screen bg-white flex">
            <div className="flex-1 flex flex-col py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                <div className="mx-auto w-full max-w-sm lg:w-96">
                    <div>
                        <img
                            className="h-12 w-auto"
                            src={logo}
                            alt="ClickAnd"
                        />
                        <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                            {t('meet.setup.member.title')}
                        </h2>
                        <p className="mt-2 text-sm text-gray-600">
                            {t('meet.setup.member.subtitle', { max_number_members: MAX_NUMBER_MEMBERS})}
                        </p>
                    </div>

                    <div className="mt-8">

                        <div className="mt-6">
                            <div

                                className="space-y-6">

                                <div className="bg-gray-50 sm:rounded-lg">
                                    <div className="px-4 py-5">
                                        <fieldset >
                                            <legend className="text-sm font-medium text-gray-700">
                                                {t('meet.setup.member.add.title')}
                                            </legend>
                                            <div className="mt-3 rounded-md shadow-sm -space-y-px">

                                                <Listbox value={selected} onChange={setSelected}>
                                                    {({ open }) => (
                                                        <>
                                                            <Listbox.Label className="sr-only">Service</Listbox.Label>
                                                            <div className="mt-1 relative">
                                                                <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-t-md pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                                                    <span className="block truncate">{selected.Name}</span>
                                                                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
                                                                </Listbox.Button>

                                                                <Transition
                                                                    show={open}
                                                                    as={Fragment}
                                                                    leave="transition ease-in duration-100"
                                                                    leaveFrom="opacity-100"
                                                                    leaveTo="opacity-0"
                                                                >
                                                                    <Listbox.Options
                                                                        static
                                                                        className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                                                                    >
                                                                        {services != undefined && services.map((service) => (
                                                                            <Listbox.Option
                                                                                key={service.id}
                                                                                className={({ active }) =>
                                                                                    classNames(
                                                                                        active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                                                                        'cursor-default select-none relative py-2 pl-3 pr-9'
                                                                                    )
                                                                                }
                                                                                value={service}
                                                                            >
                                                                                {({ selected, active }) => (
                                                                                    <>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                          {service.Name}
                        </span>

                                                                                        {selected ? (
                                                                                            <span
                                                                                                className={classNames(
                                                                                                    active ? 'text-white' : 'text-indigo-600',
                                                                                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                                                )}
                                                                                            >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                                                                                        ) : null}
                                                                                    </>
                                                                                )}
                                                                            </Listbox.Option>
                                                                        ))}
                                                                    </Listbox.Options>
                                                                </Transition>
                                                            </div>
                                                        </>
                                                    )}
                                                </Listbox>

                                                <div>
                                                    <label htmlFor="name" className="sr-only">
                                                        {t('input.name')}
                                                    </label>
                                                    <div className="flex rounded-b-md">
                                                        <div className="relative flex items-stretch flex-grow focus-within:z-10">
                                                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                                <UserIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                            </div>
                                                            <input
                                                                type="text"
                                                                name="name"
                                                                id="name"
                                                                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none pl-10 sm:text-sm border-gray-300"
                                                                placeholder={t('input.name.placeholder')}
                                                                onChange={(e) => {
                                                                    setName(e.target.value)
                                                                }}
                                                            />
                                                            {nameValidation && (
                                                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <label htmlFor="email" className="sr-only">
                                                        {t('input.email')}
                                                    </label>
                                                    <div className="flex rounded-b-md">
                                                        <div className="relative flex items-stretch flex-grow focus-within:z-10">
                                                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                                <AtSymbolIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                            </div>
                                                            <input
                                                                type="email"
                                                                name="email"
                                                                id="email"
                                                                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-b-md pl-10 sm:text-sm border-gray-300"
                                                                placeholder={t('input.email.placeholder')}
                                                                onChange={(e) => {
                                                                    setEmail(e.target.value)
                                                                }}
                                                            />
                                                            {emailValidation && (
                                                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>

                                        <div className="mt-2 grid grid-cols-2">
                                            <div className="text-sm text-red-600" id="error">
                                                {validationMessage}
                                            </div>
                                            <div className="flex justify-end">
                                                <button
                                                    onClick={addMember}
                                                    className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                                                    <UserAddIcon
                                                        className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                    <span>{t('meet.setup.member.add.button')}</span>
                                                </button>
                                            </div>
                                        </div>


                                    </div>
                                </div>

                                {
                                    members.length != 0 && (
                                        <div className="border-b border-gray-200">
                                            <ul className="divide-y divide-gray-200">
                                                {members.map((person) => (
                                                    <li key={person.email} className="py-4 flex">
                                                        <div className="ml-3 flex flex-col">
                                                        <span className="text-sm font-medium text-gray-900">
                                                            {person.name}
                                                        </span>
                                                            <span className="text-sm text-gray-500">
                                                            {person.email}
                                                        </span>
                                                            <span className="text-sm text-gray-500">
                                                            {person.service.Name}
                                                    </span>
                                                        </div>
                                                        <div className="ml-auto pl-3">
                                                            <div className="-mx-1.5 -my-1.5">
                                                                <button
                                                                    onClick={() => removeMember(person.email)}
                                                                    type="button"
                                                                    className="inline-flex rounded-md p-1.5 text-gray-300 hover:bg-gray-300 hover:text-white focus:outline-none"
                                                                >
                                                                    <span className="sr-only">Dismiss</span>
                                                                    <XIcon className="h-5 w-5" aria-hidden="true" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )
                                }

                                <div>
                                    <button
                                        type="submit"
                                        onClick={handleMembers}
                                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        {t('meet.setup.member.button')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hidden lg:block relative w-0 flex-1">
                <video
                    className="absolute inset-0 h-full w-full object-cover"
                    autoPlay
                    loop
                    playsInline
                    muted>
                    <source
                        src={dancingVideo}
                        type="video/mp4" />
                </video>
            </div>
        </div>
    )
}
